<template>
  <div v-if="canAccess('planes_read')" class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <ProgressBar mode="indeterminate" v-if="processing"/>
        <DataTable :value="items" :filters="filters" class="p-datatable-responsive"
                   v-model:selection="currentItem" selectionMode="single" dataKey="id" :paginator="true"
                   paginatorPosition="bottom" :rows="10">
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Gestionar planes</h5>
              <div>
                                 <span class="p-input-icon-left p-ml-3">
                                    <i class="pi pi-search"/>
                                    <InputText v-model="filters['global']" placeholder="Search"/>
                                 </span>
                <Button v-if="canAccess('planes_create')" label="Agregar" icon="pi pi-plus" class="p-button-success p-ml-2"
                        @click="openNew"/>
              </div>
            </div>
          </template>

          <Column field="id" header="Código" :sortable="true"></Column>
          <Column field="accounting_code" header="Código contable" :sortable="true"></Column>
          <Column field="name" header="Nombre" :sortable="true"></Column>
          <Column field="cost" header="Valor de inscripción" :sortable="true"></Column>
          <Column field="total_value" header="Valor total" :sortable="true"></Column>
          <Column field="fees" header="Cuotas" :sortable="true"></Column>
          <Column field="country.name" header="País" :sortable="true"></Column>
          <Column field="day_payment_month" header="Día Corte" :sortable="true"></Column>
          <Column>
            <template #body="slotProps">
              <Button v-if="canAccess('planes_update')" icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                      @click="edit(slotProps.data)"/>
              <Button v-if="canAccess('planes_delete')" icon="pi pi-trash" class="p-button-rounded p-button-warning"
                      @click="confirmDelete(slotProps.data)"/>
            </template>
          </Column>
        </DataTable>
        <Dialog v-if="canAccess('planes_update') || canAccess('planes_create')" v-model:visible="formDialog" :style="{width: '900px'}" header="Plan" :modal="true"
                class="p-fluid">
          <TabView class="p-mt-2">
            <TabPanel header="Datos de plan">
              <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-6">
                  <div class="p-field   p-fluid p-mt-5" v-if="currentItem">
                                        <span class="p-float-label">
                                           <InputText id="name" v-model.trim="currentItem.name" required="true"
                                                      autofocus name="plan_name"
                                                      :class="{'p-invalid': submitted && (!currentItem.name)}"
                                                      type="text"/>
                                            <label for="name">Descripción del plan</label>
                                        </span>
                    <small class="p-invalid" v-if="submitted && !currentItem.name">El nombre es
                      requerido</small>
                  </div>
                  <div class="p-field   p-fluid p-mt-5" v-if="currentItem">
                                        <span class="p-float-label">
                                           <InputText id="cost" v-model.trim="currentItem.cost" required="true"
                                                      autofocus name="cost"
                                                      :class="{'p-invalid': submitted && (currentItem.cost < 0)}"
                                                      type="number" step="0.01"/>
                                            <label for="cost">Valor de inscripción</label>
                                        </span>
                    <small class="p-invalid" v-if="submitted && currentItem.cost < 0">El valor de
                      inscripción es requerido</small>
                  </div>
                  <div class="p-field   p-fluid m p-mt-5" v-if="currentItem">
                                        <span class="p-float-label">
                                           <InputText id="fees" v-model.trim="currentItem.fees" required="true"
                                                      autofocus name="cost"
                                                      :class="{'p-invalid': submitted && (!currentItem.fees)}"
                                                      type="number" min="0"/>
                                            <label for="cost">Número de cuotas</label>
                                        </span>
                    <small class="p-invalid" v-if="submitted && !currentItem.fees">El número de cuotas
                      es requerido</small>
                  </div>
 <div class="p-field   p-fluid m p-mt-5" v-if="currentItem">
                                        <span class="p-float-label">
                                           <InputText id="day_payment_month" v-model.trim="currentItem.day_payment_month" required="true"
                                                      autofocus name="day_payment_month"
                                                      :class="{'p-invalid': submitted && (!currentItem.day_payment_month)}"
                                                      type="number" min="0"/>
                                            <label for="day_payment_month">Día de Corte</label>
                                        </span>
                    <small class="p-invalid" v-if="submitted && !currentItem.fees">El número de cuotas
                      es requerido</small>
                  </div>

                </div>
                <div class="p-col-12 p-md-6">
                  <div class="p-field   p-fluid p-mt-5" v-if="currentItem">
                                        <span class="p-float-label">
                                           <InputText id="accounting_code" v-model.trim="currentItem.accounting_code"
                                                      required="true" autofocus name="accounting_code"
                                                      :class="{'p-invalid': submitted && (!currentItem.accounting_code)}"
                                                      type="text"/>
                                            <label for="name">Código contable</label>
                                        </span>
                    <small class="p-invalid" v-if="submitted && !currentItem.accounting_code">El código
                      contable es requerido</small>
                  </div>
                  <div class="p-field   p-fluid p-mt-5" v-if="currentItem">
                                        <span class="p-float-label">
                                           <InputText id="total_value" v-model.trim="currentItem.total_value"
                                                      required="true" autofocus name="total_value"
                                                      :class="{'p-invalid': submitted && (currentItem.total_value < 0)}"
                                                      type="number" step="0.01"/>
                                            <label for="total_value">Valor total</label>
                                        </span>
                    <small class="p-invalid" v-if="submitted &&  currentItem.total_value < 0">El valor total
                      es requerido</small>
                  </div>
                  <div class="p-field   p-fluid p-mt-5" v-if="currentItem">
                                        <span class="p-float-label">
                                            <AutoComplete id="count" :dropdown="true" :multiple="false" field="name"
                                                          v-model="currentItem.country" :suggestions="countries"
                                                          @complete="getCatalogue($event, 'countries', 'name')"
                                                          :class="{'p-invalid': submitted && (!currentItem.country)}"/>
                                            <label for="count">País</label>
                                        </span>
                    <small class="p-invalid" v-if="submitted && !currentItem.country">El país es
                      requerido</small>
                  </div>
                  <div class="p-field-checkbox" v-if="currentItem">
                    <Checkbox id="binary" v-model="currentItem.valid" :binary="true"/>
                    <label for="binary"> &nbsp; Plan vigente</label>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Escala de comisiones" :disabled="!currentItem?.id">
              <commission :plan="currentItem" v-if="currentItem"></commission>
            </TabPanel>

          </TabView>
          <ProgressBar mode="indeterminate" v-if="processing"/>
          <template #footer>
            <Button label="Close" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveItem"/>
          </template>
        </Dialog>

        <Dialog v-if="canAccess('planes_delete')" v-model:visible="confirmDeleteModal" :style="{width: '450px'}" header="Confirm" :modal="true">
          <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
            <span v-if="currentItem">Seguro de eliminar registro?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text"
                    @click="confirmDeleteModal = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteRow"/>
          </template>
        </Dialog>
      </div>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>

  </div>
</template>

<script>
import service from '../../service/catalog.service'
import {mapState} from "vuex";
import {defineAsyncComponent} from "vue";
import bouncer from "../../../../helpers/bouncer";

export default {
  mixins: [bouncer],
  name: 'Plans',
  components: {
    commission: defineAsyncComponent(() => import('./Commission')),
  },
  data() {
    return {
      path: 'plans',
      confirmDeleteModal: false,
      processing: false,
      submitted: false,
      formDialog: false,
      filters: {},
      items: null,
      currentItem: null,
      layout: 'list',
      countries: []
    }
  },
  carService: null,
  eventService: null,
  nodeService: null,
  created() {

  },
  mounted() {
    if(this.canAccess('planes_read')){
      this.getData()
    }

  },
  methods: {
    saveItem() {
      this.submitted = true;
      if (!this.currentItem.name || this.currentItem.fees < 0|| this.currentItem.day_payment_month < 0 || !this.currentItem.cost ||
          !this.currentItem.country || !this.currentItem.accounting_code || this.currentItem.total_value < 0) {
        return;
      }
      this.processing = true;
      this.currentItem.country_id = this.currentItem.country.id;
      this.currentItem.description = "n/a";
      service.save(this.path, this.currentItem, this.currentItem ? this.currentItem.id : undefined).then((x) => {
        this.currentItem.id = x.data.id;
        this.getData();
        this.$toast.add({
          severity: 'success',
          summary: 'Registro guardado',
          detail: 'Se guardó correctamente',
          life: this.$utils.toastLifeTime()
        });
        // this.formDialog = false;
      }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => {
        this.submitted = false;
        this.processing = false;
      });
    },
    hideDialog() {
      this.submitted = false;
      this.currentItem = {};
      this.formDialog = false;
    },
    openNew() {
      this.submitted = false;
      this.currentItem = {};
      this.formDialog = true;
    },
    getData() {
      this.processing = true;
      service.get(this.path).then(x => {
        this.items = x.data;
      }).catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'se a producido un error',
          life: this.$utils.toastLifeTime()
        });
      }).finally(() => {
        this.processing = false;
      })
    },
    confirmDelete(data) {
      this.currentItem = data;
      this.confirmDeleteModal = true;
    },
    edit(item) {
      this.currentItem = item;
      this.submitted = false;
      this.formDialog = true;

    },
    deleteRow() {
      this.processing = true;
      this.confirmDeleteModal = false;
      service.delete(this.path, this.currentItem.id).then(() => {
        this.currentItem = {};
        this.$toast.add({
          severity: 'success',
          summary: 'Registro eliminado',
          detail: 'Se eliminó correctamente',
          life: this.$utils.toastLifeTime()
        });

        this.getData();
      }).finally(() => {
        this.processing = false;
      });
    },
    getCatalogue(event, route, field) {
      service.getCatalog(event, route, field).then(x => {
        this[route] = x;
      });
    }
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
  }
}
</script>

<style scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-progressbar {
  height: 1px;
}
</style>
